import React, { useEffect, useState, useRef } from 'react';
import contentfulClient from '../contentfulClient';

// Add display name to RotatingElement
const RotatingElement = React.memo(() => (
  <div className="rotating-text-container">
    <svg viewBox="0 0 100 100" className="rotating-text-svg">
      <path
        id="text-path"
        d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
        fill="none"
      />
      <text className="rotating-text">
        <textPath href="#text-path" startOffset="0">
          ✦ NICE ✦ TO ✦ MEET ✦ YOU
        </textPath>
      </text>
    </svg>
    <div className="rotating-image">
      <img 
        src="https://framerusercontent.com/images/SU7FARlpGIGwn6eTuVOycTV6Q.png" 
        alt="Rotating"
        loading="eager"
      />
    </div>
  </div>
));
RotatingElement.displayName = 'RotatingElement';

// Add display name to BannerText
const BannerText = ({ text, delay }) => (
  <h1 
    className="banner-text-animated"
    style={{ animationDelay: `${delay}s` }}
  >
    {text}
  </h1>
);
BannerText.displayName = 'BannerText';

const Banner = () => {
  const [bannerItems, setBannerItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const bannerRef = useRef(null);
  const mainImageContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (mainImageContainerRef.current) {
        const scrollPosition = window.scrollY;
   
        if (scrollPosition > 100) {
          mainImageContainerRef.current.classList.add('scrolled-half');
        } else {
          mainImageContainerRef.current.classList.remove('scrolled-half');
        }
      }
    };
   
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
   }, []);

  useEffect(() => {
    const fetchBannerItems = async () => {
      try {
        const cached = sessionStorage.getItem('bannerItems');
        if (cached) {
          const { items, timestamp } = JSON.parse(cached);
          const isCacheValid = Date.now() - timestamp < 5 * 60 * 1000;
          
          if (isCacheValid) {
            setBannerItems(items);
            setIsLoading(false);
            return;
          }
        }

        const response = await contentfulClient.getEntries({
          content_type: 'homeBanner',
          include: 2
        });

        sessionStorage.setItem('bannerItems', JSON.stringify({
          items: response.items,
          timestamp: Date.now()
        }));

        setBannerItems(response.items);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching banner items:", error);
        setError("Failed to load banner content");
        setIsLoading(false);
      }
    };

    fetchBannerItems();
  }, []);

  useEffect(() => {
    if (bannerRef.current) {
      bannerRef.current.classList.add('banner-visible');
    }
  }, [bannerItems]);

  if (error) return <div className="banner-error">{error}</div>;
  if (isLoading) return <div className="banner-skeleton" />;

  return (
    <div ref={bannerRef} className="banner-wrapper">
      {bannerItems.map((item) => (
        <div key={item.sys.id} className="container">
          <div className="Banner">
            <div className="banner-content w-100">
              <div className="banner-text-container">
                <BannerText text={item.fields.mainText} delay={0} />
                <BannerText text={item.fields.titleLine2} delay={0.1} />
                <BannerText text={item.fields.titleLine3} delay={0.2} />
              </div>
              
              <div className="banner-paragraph w-50">
                <div 
                  className="banner-text-animated"
                  style={{ animationDelay: '0.3s' }}
                >
                  <h4>{item.fields.paragraph}</h4>
                </div>
              </div>
            </div>

            <div className="right-banner">
             
              <div className="images banner-image-animated">
              <div className='animated-line'>
                  <svg width="326" height="126" viewBox="0 0 326 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className="path" d="M2 104.078C7.33333 76.5779 42.9 18.0779 142.5 4.07793C242.1 -9.92207 242.667 50.2446 230.5 82.0779C214.669 123.5 150.121 139.5 147 104.078C142.5 53 216 16.8948 325 32.5" stroke="#8589ac" strokeWidth="3.5"/>
                </svg>
              </div>
               
                <div ref={mainImageContainerRef} className="main-image-container">
                  <img
                    className="Main-Image main-img"
                    src={item.fields.bannerImage.fields.file.url}
                    alt={item.fields.bannerImage.fields.title || 'Banner'}
                    loading="eager"
                    onLoad={(e) => {
                      e.target.classList.add('loaded');
                    }}
                  />
                </div>
                <div className="rotation">
                  <RotatingElement />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Add display name to Banner component
Banner.displayName = 'Banner';

// Add display name to memoized version
const MemoizedBanner = React.memo(Banner);
MemoizedBanner.displayName = 'MemoizedBanner';

export default MemoizedBanner;