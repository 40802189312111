// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar_menu';

const Header = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`py-3 ${isScrolled ? 'scrolled' : ''}`}>

            <div className="container">
                <div className='row align-items-center'>
                    <div className='col'>
                    <Link className='Logo' to="/">
                    
          {/* SVG Here */}
          
          <svg width="44" height="44" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="130" height="130" rx="65" fill="#202858"/>
            <path d="M70.209 109.081H60.0872C57.6867 109.081 55.7407 107.135 55.7407 104.735V78.4912C55.7407 76.0907 57.6867 74.1447 60.0872 74.1447H70.209C72.6095 74.1447 74.5555 76.0907 74.5555 78.4912V104.735C74.5555 107.135 72.6095 109.081 70.209 109.081Z" fill="white"/>
            <path d="M103.96 102.471V29.232C103.96 29.232 103.842 21.568 96.5769 20.8788H90.152C90.152 20.8788 89.9118 20.9065 89.5427 20.9887H88.3936L68.9237 21.0084L69.8151 21.01C69.8151 21.01 68.7925 21.0116 67.7248 21.0132C67.3368 20.9428 66.9243 20.9041 66.4833 20.9041L64.6341 20.9405C64.6277 20.9381 60.1611 20.931 60.1611 20.931H61.0525L41.5826 20.9104H40.4335C40.0652 20.8283 39.825 20.7998 39.825 20.7998H33.3993C26.1351 21.4889 26.0166 29.786 26.0166 29.786V102.392C25.6609 107.945 31.1541 108.468 31.1541 108.468L38.6633 108.531C43.2129 108.265 42.8012 104.402 42.8012 104.402L42.719 38.2767C42.6518 35.6348 44.8409 35.3732 45.3166 35.212L52.9981 35.1701C53.3458 35.2942 55.8494 34.8445 56.0485 38.8852L56.0699 38.8465L56.1252 61.5929C56.1252 61.5929 56.2279 64.0183 58.6896 64.6426C59.2665 64.852 59.7162 64.8852 59.7162 64.8852L69.6041 64.8528C73.7056 65.112 73.8518 61.672 73.8518 61.672L73.9063 38.9255L73.9285 38.9642C74.1276 34.9236 76.6312 35.3732 76.9789 35.2492L84.6596 35.291C85.1361 35.4523 87.3252 35.7138 87.258 38.3557L87.175 104.481C87.175 104.481 86.7641 108.344 91.3129 108.61L98.8229 108.546C98.8229 108.546 104.316 108.024 103.96 102.471Z" fill="white"/>
            </svg>




                    {/* <span className='ms-2'>M.Hijas</span> */}
                    
                    </Link>
                    </div>

                    <NavBar/>

                   
                </div>
            </div>
        </header>
    );
};

export default Header;