import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Portfolio from "./Portfolio";
import Articles from "./Articles";
import Community from './Community';
import SideProjects from "./SideProjects";
import Banner from './Banner';
import Tools from './toolsLogoes';
import ClientLogoes from './ClientLogoes';

// Image cache context
export const ImageCacheContext = React.createContext({});

// Image cache provider component
const ImageCacheProvider = ({ children }) => {
  const [imageCache, setImageCache] = useState(new Map());

  const cacheImage = (src) => {
    if (!imageCache.has(src)) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImageCache(prev => new Map(prev.set(src, img)));
      };
    }
  };

  return (
    <ImageCacheContext.Provider value={{ imageCache, cacheImage }}>
      {children}
    </ImageCacheContext.Provider>
  );
};

// Optimized image component
const OptimizedImage = ({ src, alt, className, ...props }) => {
  const { imageCache, cacheImage } = React.useContext(ImageCacheContext);
  const [isLoading, setIsLoading] = useState(!imageCache.has(src));
  const imgRef = useRef(null);

  useEffect(() => {
    if (!imageCache.has(src)) {
      cacheImage(src);
    }
  }, [src, cacheImage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsLoading(false);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: '50px',
        threshold: 0.1
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={src}
      alt={alt}
      className={`${className} ${isLoading ? 'image-loading' : 'image-loaded'}`}
      loading="lazy"
      {...props}
    />
  );
};

const HomePage = () => {
  const location = useLocation();
  const [contentVisible, setContentVisible] = useState(false);
  const wrapContentRef = useRef(null); // Ref for the Wrap-Content div

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  useEffect(() => {
    // Add browser caching headers
    const metaElement = document.createElement('meta');
    metaElement.httpEquiv = 'Cache-Control';
    metaElement.content = 'max-age=31536000'; // Cache for 1 year
    document.head.appendChild(metaElement);

    // Enable content after initial load
    setContentVisible(true);

    return () => {
      document.head.removeChild(metaElement);
    };
  }, []);

  useEffect(() => {
    // Add the class dynamically on page load
    const wrapContentElement = wrapContentRef.current;
    if (wrapContentElement) {
      wrapContentElement.classList.add("fade-enter-active");
    }
  }, []);

  // Add loading state for sections
  const [sectionsLoaded, setSectionsLoaded] = useState({
    portfolio: false,
    tools: false,
    articles: false,
    sideProjects: false,
    clients: false
  });

  const handleSectionLoad = (section) => {
    setSectionsLoaded(prev => ({
      ...prev,
      [section]: true
    }));
  };

  return (
    <ImageCacheProvider>
      <div className="HomePage">
        <Banner />

        {/* <img className="React" src="/Made-with-react.png" /> */}

        <div className="Wrap-Content fade-enter" ref={wrapContentRef}>
          <div className="Portfolio-Listing">
            <div className="container">
              <Portfolio 
                showFeatured="true"
                onLoad={() => handleSectionLoad('portfolio')}
                OptimizedImage={OptimizedImage}
                stepNumber={"01"}
              />
            </div>
          </div>

          <div className="Tools-Listing">
            <div className="container">
              <Tools
                onLoad={() => handleSectionLoad('tools')}
                OptimizedImage={OptimizedImage}
              />
              <h5 className="text-center">Behind the Magic</h5>
            </div>
          </div>

          <div className="Article-Listing">
            <div className="container">
              <Articles
                onLoad={() => handleSectionLoad('articles')}
                OptimizedImage={OptimizedImage}
                showFeatured="true"
                stepNumber={"03"}
              />
            </div>
          </div>

          <div className="Client-Listing mt-5 pt-3">
            <div className="container">
              <ClientLogoes
                onLoad={() => handleSectionLoad('clients')}
                OptimizedImage={OptimizedImage}
                stepNumber={"04"}
              />
            </div>
          </div>

          <div className="Community-Listing mt-5">
            <div className="container">
              <Community
                onLoad={() => handleSectionLoad('Community')}
                OptimizedImage={OptimizedImage}
                stepNumber={"05"}
              />
            </div>
          </div>

          <div className="Side-projects mt-5 pt-3">
            <div className="container">
              <SideProjects
                onLoad={() => handleSectionLoad('sideProjects')}
                OptimizedImage={OptimizedImage}
                stepNumber={"06"}
              />
            </div>
          </div>
        </div>
      </div>
    </ImageCacheProvider>
  );
};

export default HomePage;