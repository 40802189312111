import React, { useEffect, useState, useRef, useCallback } from "react";
import contentfulClient from "../contentfulClient";

const Archive = () => {
  const [communityItems, setCommunityItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const ITEMS_PER_PAGE = 15;
  
  const imagesLoadedRef = useRef({});
  const imageCache = useRef(new Map());
  const loadingRef = useRef(null);
  const [forceRender, setForceRender] = useState(0);

  const cacheImage = useCallback((url) => {
    if (!imageCache.current.has(url)) {
      const img = new Image();
      img.src = url;
      imageCache.current.set(url, img);
    }
  }, []);

  // Fetch all items initially
  useEffect(() => {
    const fetchCommunityItems = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await contentfulClient.getEntries({
          content_type: "archives",
        });

        let sortedItems = response.items.map((item, index) => ({
          ...item,
          fields: {
            ...item.fields,
            order: item.fields.order ?? index + 1,
          },
        }));

        sortedItems = sortedItems.sort((a, b) => a.fields.order - b.fields.order);

        sortedItems.forEach((item) => {
          if (item.fields.image?.fields.file.url) {
            cacheImage(item.fields.image.fields.file.url);
          }
        });

        console.log('Initial data fetch:', {
          totalItems: sortedItems.length,
          firstItem: sortedItems[0]?.fields.projectName,
          lastItem: sortedItems[sortedItems.length - 1]?.fields.projectName
        });
        
        setCommunityItems(sortedItems);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching community items:", err);
        setError("Failed to load archive items. Please try again later.");
        setLoading(false);
      }
    };

    fetchCommunityItems();
  }, [cacheImage]);

  // Load more items when scrolling
  useEffect(() => {
    const loadMoreItems = () => {
      const startIndex = page * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      const newItems = communityItems.slice(0, endIndex);
      
      console.log('Loading more items:', {
        page,
        startIndex,
        endIndex,
        totalItems: communityItems.length,
        newItemsCount: newItems.length,
        hasMore: endIndex < communityItems.length
      });
      
      setDisplayedItems(newItems);
      setHasMore(endIndex < communityItems.length);
    };

    loadMoreItems();
  }, [page, communityItems]);

  // Intersection Observer setup
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      console.log('Intersection Observer:', {
        isIntersecting: entry.isIntersecting,
        hasMore,
        loading,
        currentPage: page
      });
      
      if (entry.isIntersecting && hasMore && !loading) {
        console.log('Loading next page...');
        setPage((prevPage) => prevPage + 1);
      }
    }, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, loading]);

  const handleImageLoad = (id) => {
    imagesLoadedRef.current[id] = true;
    setForceRender((prev) => prev + 1);
  };

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Reload</button>
      </div>
    );
  }

  return (
    <div className="mt-4 pt-4 mb-4 pb-4 archive-wrapper">
      <div className="container mt-5 pt-5 mb-5 pb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="name-heading">Archive</h1>
            <div className="banner-text-animated" style={{ animationDelay: "0.1s" }}>
              <h4>
                Beyond the featured case studies, a collection of selected
                projects I&apos;ve crafted over the years.
              </h4>
            </div>
          </div>
        </div>
      </div>

      {loading && page === 0 ? (
        <div className="container">
          <div className="row">
            {[1, 2, 3].map((i) => (
              <div key={i} className="col-md-4">
                <div className="wrap border_r skeleton">
                  <div className="thumbnail skeleton-animation"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row row-archive fade-in">
            {displayedItems.map((item) => (
              <div className="col-md-4" key={item.sys.id}>
                <div className="wrap border_r">
                  <div
                    className="thumbnail mb-0 pb-0"
                    style={{
                      position: "relative",
                      paddingBottom: "66.67%",
                      background: "#f5f5f5",
                      overflow: "hidden",
                    }}
                  >
                    {item.fields.image && (
                      <img
                        className={`w-100 ${
                          imagesLoadedRef.current[item.sys.id] ? "loaded" : "loading"
                        }`}
                        alt={item.fields.projectName}
                        src={
                          imageCache.current.has(item.fields.image.fields.file.url)
                            ? imageCache.current.get(item.fields.image.fields.file.url).src
                            : item.fields.image.fields.file.url
                        }
                        onLoad={() => handleImageLoad(item.sys.id)}
                      />
                    )}
                  </div>
                  <div className="community-item">
                    <div className="information">
                      <div className="p-4">
                        <h4>{item.fields.projectName}</h4>
                        <div className="label-wrap mt-3">
                          {item.fields.tags?.split(",").map((label, index) => (
                            <span key={`task-${index}`}>{label.trim()}</span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Loading indicator for infinite scroll */}
          {hasMore && (
            <div ref={loadingRef} className="text-center py-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// Add display name to resolve ESLint warning
Archive.displayName = 'Archive';

export default Archive;