import React, { useState, useEffect, useRef } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.scss";
import ReactGA4 from "react-ga4";

import MainNav from "./components/NavBar";
import Footer from "./components/Footer";
import ScrollManager from "./components/ScrollManager";
import StarRating from "./components/Comps/StarRating";
import { NavigationProvider } from "./components/Comps/NavigationContext";
import VisitorTracker from "./components/Comps/VisitorTracker";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import ContactQRCode from "./components/Comps/getInTouch";

function App() {
  const location = useLocation();
  const [isScrollRestored, setIsScrollRestored] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ratingPopupVisible, setRatingPopupVisible] = useState(true);
  const nodeRef = useRef(null);

  const handleScrollRestored = () => {
    setIsScrollRestored(true);
  };

  // Initialize Google Analytics once
  useEffect(() => {
    ReactGA4.initialize("G-S868L1LSPX");
  }, []);

  // Track pageviews and update page titles dynamically
  useEffect(() => {
    const routeTitles = {
      "/": "Welcome to My Portfolio 2025",
      "/portfolio": "Portfolio Page",
      "/about": "About Me",
      "/Case_Studies": "Case Studies",
      "/Archive": "Archive",
      "/Case_studies/Investnation": "Investnation",
      "/case_studies/Porsche%20Gaming%20Challenge%20": "Porsche Gaming Challenge", 
      "/case_studies/Digital%20Onboarding" : "Digital Onboarding",
      "/Case_studies/Dhabi":"Dhabi",

      
      // Add more routes as necessary
    };

    // Set dynamic page title
    const currentTitle = routeTitles[location.pathname] || "Portfolio 2025";
    document.title = currentTitle;

    // Send pageview to Google Analytics
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname + location.search, // Include query parameters
      title: currentTitle,
    });

  }, [location]); // Triggered whenever the route changes

  // Simulate loading behavior
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 100); // Simulates network delay
    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, [location.key]);

  const appContent = (
    <div className="App">
      <ScrollManager onScrollRestored={handleScrollRestored} />
      <MainNav />

      <main className="main-content">
        <TransitionGroup>
          <CSSTransition
            nodeRef={nodeRef}
            key={location.key}
            classNames="fade"
            timeout={300}
            unmountOnExit
          >
            <div ref={nodeRef}>
              {loading ? (
                <div className="loading-placeholder">Loading...</div>
              ) : (
                <Outlet />
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </main>

      <ContactQRCode/>
    
      <Footer />

      {ratingPopupVisible && (
        <StarRating onClose={() => setRatingPopupVisible(false)} />
      )}

      <VisitorTracker />
      <SpeedInsights />
      <Analytics />
    </div>
  );

  return <NavigationProvider>{appContent}</NavigationProvider>;
}

export default App;