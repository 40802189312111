import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react'; // Fixed import

const ContactQRCode = () => {
  const [activeTab, setActiveTab] = useState('email');
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Simplified email URL - removed subject and message
  const contactData = {
    email: {
      url: 'mailto:design.hijas@gmail.com', // Simplified - just the email address
      display: 'design.hijas@gmail.com',
      action: 'send an email'
    },
    call: {
      url: '#', // Placeholder, will be set dynamically
      display: '+971 54 449 ****', // Partially masked for display
      action: 'make a call'
    }
  };

  // Set the phone number dynamically after component mounts to avoid crawlers
  useEffect(() => {
    // Dynamically construct the phone number to avoid crawling
    const parts = ['tel:+', '971', '544', '490', '084'];
    const phoneUrl = parts.join('');
    
    // Update the URL only on client-side
    if (typeof window !== 'undefined') {
      contactData.call.url = phoneUrl;
    }
  }, []);
  
  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  // Toggle expanded state
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle phone call button click with additional security
  const handlePhoneClick = (e) => {
    if (activeTab === 'call') {
      e.preventDefault();
      
      // Dynamically construct the phone number only when needed
      const parts = ['tel:+', '971', '544', '490', '084'];
      const phoneUrl = parts.join('');
      
      // Small delay to prevent automated systems from detecting patterns
      setTimeout(() => {
        window.location.href = phoneUrl;
      }, 50);
    }
  };
  
  return (
    <div className="contact-qr__container">
      {/* Mobile icon when collapsed */}
      {!isExpanded && (
        <button 
          className="contact-qr__button"
          onClick={toggleExpanded}
          aria-label="Open contact options"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.75 4C4.75 2.48122 5.98122 1.25 7.5 1.25H16.5C18.0188 1.25 19.25 2.48122 19.25 4V20C19.25 21.5188 18.0188 22.75 16.5 22.75H7.5C5.98122 22.75 4.75 21.5188 4.75 20V4ZM11 19C11 18.4477 11.4477 18 12 18H12.009C12.5613 18 13.009 18.4477 13.009 19C13.009 19.5523 12.5613 20 12.009 20H12C11.4477 20 11 19.5523 11 19ZM11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H11Z" fill="currentColor" />
          </svg>
        </button>
      )}
      
      {/* QR Code Box */}
      {isExpanded && (
        <div className="contact-qr__card">
          {/* Header */}
          <div className="contact-qr__header">
            <h3 className="contact-qr__title">Get in Touch</h3>
            <button 
              className="contact-qr__close-button"
              onClick={toggleExpanded}
              aria-label="Close contact panel"
            >
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" width="20" height="20">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          
          {/* Tabs */}
          <div className="contact-qr__tab-container">
            <div className="contact-qr__tabs">
              <div 
                className={`contact-qr__tab-indicator ${
                  activeTab === 'email' 
                    ? 'contact-qr__tab-indicator--email' 
                    : 'contact-qr__tab-indicator--call'
                }`}
              ></div>
              
              <button
                className={`contact-qr__tab ${
                  activeTab === 'email' 
                    ? 'contact-qr__tab--active' 
                    : 'contact-qr__tab--inactive'
                }`}
                onClick={() => handleTabChange('email')}
              >
                <span className="contact-qr__tab-label">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 3C2.23122 3 1 4.23122 1 5.75V17.75C1 19.2688 2.23122 20.5 3.75 20.5H19.75C21.2688 20.5 22.5 19.2688 22.5 17.75V5.75C22.5 4.23122 21.2688 3 19.75 3H3.75ZM6.12803 7.10229C5.77024 6.89358 5.311 7.01443 5.10229 7.37222C4.89358 7.73001 5.01443 8.18925 5.37222 8.39796L10.3645 11.3101C11.2207 11.8096 12.2795 11.8096 13.1358 11.3101L18.128 8.39796C18.4858 8.18925 18.6067 7.73001 18.398 7.37222C18.1892 7.01443 17.73 6.89358 17.3722 7.10229L12.38 10.0144C11.9908 10.2415 11.5095 10.2415 11.1203 10.0144L6.12803 7.10229Z" fill="currentColor" />
                  </svg>
                  <span className="contact-qr__tab-text">Email</span>
                </span>
              </button>
              <button
                className={`contact-qr__tab ${
                  activeTab === 'call' 
                    ? 'contact-qr__tab--active' 
                    : 'contact-qr__tab--inactive'
                }`}
                onClick={() => handleTabChange('call')}
              >
                <span className="contact-qr__tab-label">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.75 4C4.75 2.48122 5.98122 1.25 7.5 1.25H16.5C18.0188 1.25 19.25 2.48122 19.25 4V20C19.25 21.5188 18.0188 22.75 16.5 22.75H7.5C5.98122 22.75 4.75 21.5188 4.75 20V4ZM11 19C11 18.4477 11.4477 18 12 18H12.009C12.5613 18 13.009 18.4477 13.009 19C13.009 19.5523 12.5613 20 12.009 20H12C11.4477 20 11 19.5523 11 19ZM11 4C10.4477 4 10 4.44772 10 5C10 5.55228 10.4477 6 11 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H11Z" fill="currentColor" />
                  </svg>
                  <span className="contact-qr__tab-text">Call</span>
                </span>
              </button>
            </div>
          </div>
          
          {/* QR Code */}
          <div className="contact-qr__qr-container">
            {activeTab === 'email' ? (
              <a 
                href={contactData.email.url} 
                className="contact-qr__qr-wrapper"
                aria-label={`Click to ${contactData.email.action}`}
              >
                <QRCodeSVG 
                  value={contactData.email.url}
                  size={120}
                  level="H" // Highest error correction level
                  includeMargin={true}
                  bgColor="#ffffff"
                  fgColor="#161e47"
                />
              </a>
            ) : (
              <div 
                className="contact-qr__qr-wrapper"
                onClick={handlePhoneClick}
                style={{ cursor: 'pointer' }}
              >
                {/* Phone QR code is generated on client-side only */}
                {typeof window !== 'undefined' && (
                  <QRCodeSVG 
                    value={`tel:+971544490084`} // The QR code itself is safe as it's never in source code
                    size={120}
                    level="H"
                    includeMargin={true}
                    bgColor="#ffffff"
                    fgColor="#161e47"
                  />
                )}
              </div>
            )}
          </div>
          
          {/* Instruction text */}
          <div className="contact-qr__instruction-text">
            Scan with your phone camera to {contactData[activeTab].action} OR
          </div>
          
          {/* Contact info (also clickable) */}
          <div className="contact-qr__contact-info">
            {activeTab === 'email' ? (
              <a href={contactData.email.url} className="contact-qr__contact-link underline">
                <p className='small-text'>
                 Click here
                  </p>
              </a>
            ) : (
              <p className="contact-qr__contact-number small-text underline" onClick={handlePhoneClick}>
                 Click here
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactQRCode;