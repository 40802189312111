import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import contentfulClient from '../contentfulClient';

// Add display name to PortfolioItem
const PortfolioItem = React.memo(({ item, OptimizedImage, onImageLoad }) => {
  const [isVisible, setIsVisible] = useState(false);
  const itemRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.unobserve(itemRef.current);
        }
      },
      { threshold: 0.1 }
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, []);

  const ImageComponent = OptimizedImage || 'img';

  return (
    <Link
      ref={itemRef}
      className={`single-portfolio ${item.fields.thumbnail.fields.description + " " + item.fields.name.replace(/\s+/g, '')} ${
        isVisible ? 'fade-in' : ''
      }`}
      to={`/Case_studies/${item.fields.name}`}
      state={{ portfolioItem: item.fields, allItems: item.allItems }}
    >
      <div 
        className="portfolio-item"
        style={{ backgroundColor: item.fields.innerBannerColor }}
      >
        {item.fields.appLogo?.fields?.file?.url && (
          <ImageComponent
            src={item.fields.appLogo.fields.file.url}
            alt={item.fields.name || 'App Logo'}
            className="app-logo dynamic-width"
            style={{
              '--dynamic-width': `${Number(item.fields.appLogo.fields.description)}px`,
            }}
            onLoad={() => onImageLoad(item.sys.id, 'logo')}
            onError={(e) => {
              console.error(`Error loading logo for ${item.fields.name}`);
              e.target.style.display = 'none';
            }}
          />
        )}

        <div className="information">
          <strong>{item.fields.projectType}</strong>
          <h3>{item.fields.name}</h3>
          <p>{item.fields.shortDescription}</p>
        </div>

        {item.fields.thumbnail && (
          <ImageComponent
            className="thumbnail-large"
            src={item.fields.thumbnail.fields.file.url}
            alt={item.fields.title}
            onLoad={() => onImageLoad(item.sys.id, 'thumbnail')}
            onError={(e) => {
              console.error(`Error loading thumbnail for ${item.fields.name}`);
              e.target.style.display = 'none';
            }}
          />
        )}
      </div>
    </Link>
  );
});
PortfolioItem.displayName = 'PortfolioItem';

const Portfolio = ({ showFeatured, OptimizedImage, stepNumber }) => {
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadedImages, setLoadedImages] = useState(new Set());

  const fetchPortfolioItems = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      const cached = sessionStorage.getItem('portfolioItems');
      if (cached) {
        const { items, timestamp } = JSON.parse(cached);
        const isCacheValid = Date.now() - timestamp < 5 * 60 * 1000;
        
        if (isCacheValid) {
          let sortedItems = items.sort((a, b) => a.fields.order - b.fields.order);
          if (showFeatured) {
            sortedItems = sortedItems.filter(item => item.fields.featured === true);
          }
          setPortfolioItems(sortedItems);
          setLoading(false);
          return;
        }
      }

      const response = await contentfulClient.getEntries({
        content_type: 'portfolioHij',
        include: 2,
      });

      let sortedItems = response.items.sort((a, b) => a.fields.order - b.fields.order);
      
      if (showFeatured) {
        sortedItems = sortedItems.filter(item => item.fields.featured === true);
      }

      sessionStorage.setItem('portfolioItems', JSON.stringify({
        items: response.items,
        timestamp: Date.now()
      }));

      setPortfolioItems(sortedItems);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching portfolio items:", error);
      setError("Failed to load portfolio items. Please try again later.");
      setLoading(false);
    }
  }, [showFeatured]);

  useEffect(() => {
    fetchPortfolioItems();
  }, [fetchPortfolioItems]);

  const handleImageLoad = useCallback((itemId, type) => {
    setLoadedImages(prev => new Set(prev.add(`${itemId}-${type}`)));
  }, []);

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={fetchPortfolioItems}>Try Again</button>
      </div>
    );
  }

  return (
    <div>
      <h2 className="title-sub"><span>{stepNumber}</span> Work / Case Study</h2>
      
      {loading ? (
        <div className="portfolio-grid-skeleton">
          {[1, 2, 3].map(i => (
            <div key={i} className="portfolio-item-skeleton">
              <div className="skeleton-animation"></div>
            </div>
          ))}
        </div>
      ) : (
        <div className="portfolio-grid">
          {portfolioItems.map((item) => (
            <PortfolioItem
              key={item.sys.id}
              item={{...item, allItems: portfolioItems}}
              OptimizedImage={OptimizedImage}
              onImageLoad={handleImageLoad}
            />
          ))}
        </div>
      )}
    </div>
  );
};

// Add display name to Portfolio component
Portfolio.displayName = 'Portfolio';

// Add display name to memoized version
const MemoizedPortfolio = React.memo(Portfolio);
MemoizedPortfolio.displayName = 'MemoizedPortfolio';

export default MemoizedPortfolio;