import React, { useEffect, useRef } from 'react';
import { createClient } from 'contentful-management';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import ReactGA4 from 'react-ga4';

// Constants
const PRODUCTION_DOMAIN = 'mohij.com';
const GA_MEASUREMENT_ID = 'G-S868L1LSPX';
const CONTENTFUL_SPACE_ID = '7lqh82unq55r';

// Initialize Contentful client
const managementClient = createClient({
    accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_TOKEN || 'CFPAT-0mpIGgPEN-Ir4z8LpMbTwdcEcL3rXLA4mLfbgJPE548'
});

const VisitorTracker = ({ debug = false }) => {
    const updateInProgress = useRef(false);
    const mounted = useRef(false);
    const gaInitialized = useRef(false);

    // Check if tracking should be enabled
    const isTrackingEnabled = () => {
        const hostname = window.location.hostname;
        const isProductionDomain = hostname === PRODUCTION_DOMAIN || hostname === `www.${PRODUCTION_DOMAIN}`;
        const isTrackingFlagEnabled = process.env.REACT_APP_ENABLE_TRACKING === 'true';
        
        if (debug) {
            console.log('Tracking enabled:', isTrackingFlagEnabled || isProductionDomain);
        }
        return isProductionDomain || isTrackingFlagEnabled;
    };

    // Get or create visitor ID
    const getOrCreateVisitorId = () => {
        let visitorId = localStorage.getItem('visitor_id');
        if (!visitorId) {
            visitorId = uuidv4();
            localStorage.setItem('visitor_id', visitorId);
            if (debug) {
                console.log('New visitor ID created:', visitorId);
            }
        }
        return visitorId;
    };

    // Get enhanced device information
    const getEnhancedDeviceInfo = () => {
        const parser = new UAParser();
        const result = parser.getResult();

        return {
            deviceInfo: {
                type: result.device.type || 'Desktop',
                model: result.device.model || 'Unknown',
                vendor: result.device.vendor || 'Unknown',
            },
            browserInfo: {
                name: result.browser.name || 'Unknown',
                version: result.browser.version || 'Unknown',
                language: navigator.language,
                cookiesEnabled: navigator.cookieEnabled,
                userAgent: navigator.userAgent,
            },
            osInfo: {
                name: result.os.name || 'Unknown',
                version: result.os.version || 'Unknown',
            },
            displayInfo: {
                screenSize: `${window.screen.width}x${window.screen.height}`,
                viewportSize: `${window.innerWidth}x${window.innerHeight}`,
                colorDepth: window.screen.colorDepth,
                pixelRatio: window.devicePixelRatio || 1,
            }
        };
    };

    // Initialize Google Analytics
    const initializeGA = () => {
        if (!gaInitialized.current) {
            ReactGA4.initialize(GA_MEASUREMENT_ID, {
                debug: process.env.NODE_ENV === 'development' || debug,
                testMode: process.env.NODE_ENV === 'development',
                gaOptions: {
                    cookieFlags: 'SameSite=None;Secure'
                }
            });
            gaInitialized.current = true;
            if (debug) {
                console.log('GA4 initialized with measurement ID:', GA_MEASUREMENT_ID);
            }
        }
    };

    // Send events to Google Analytics
    const sendGAEvents = (visitorId, deviceData) => {
        // Set user ID and default parameters
        ReactGA4.set({
            userId: visitorId,
            user_properties: {
                visitor_type: 'website_visitor',
                first_visit_date: localStorage.getItem('first_visit_date') || new Date().toISOString()
            }
        });

        // Send pageview
        ReactGA4.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: document.title,
            userId: visitorId
        });

        // Send visitor data event
        const visitorEvent = {
            visitor_id: visitorId,
            device_type: deviceData.deviceInfo.type,
            device_model: deviceData.deviceInfo.model,
            device_vendor: deviceData.deviceInfo.vendor,
            browser_name: deviceData.browserInfo.name,
            browser_version: deviceData.browserInfo.version,
            browser_language: deviceData.browserInfo.language,
            os_name: deviceData.osInfo.name,
            os_version: deviceData.osInfo.version,
            screen_size: deviceData.displayInfo.screenSize,
            viewport_size: deviceData.displayInfo.viewportSize,
            referrer: document.referrer || 'Direct',
            non_interaction: true
        };

        ReactGA4.event('visitor_data', visitorEvent);

        if (debug) {
            console.log('GA4 event sent:', visitorEvent);
        }
    };

    // Save data to Contentful
    const saveToContentful = async (visitorId, deviceData) => {
        const space = await managementClient.getSpace(CONTENTFUL_SPACE_ID);
        const environment = await space.getEnvironment('master');

        // Format device information as text with pipe separators
        const deviceInfoText = `type: ${deviceData.deviceInfo.type} | model: ${deviceData.deviceInfo.model} | vendor: ${deviceData.deviceInfo.vendor} | platform: ${navigator.platform} | touchPoints: ${navigator.maxTouchPoints} | hardwareConcurrency: ${navigator.hardwareConcurrency}`;

        // Format display information
        const displayInfoText = `screenSize: ${deviceData.displayInfo.screenSize} | viewportSize: ${deviceData.displayInfo.viewportSize} | colorDepth: ${deviceData.displayInfo.colorDepth} | pixelRatio: ${deviceData.displayInfo.pixelRatio} | orientation: ${window.screen?.orientation?.type || 'landscape-primary'}`;

        // Format browser information
        const browserInfoText = `name: ${deviceData.browserInfo.name} | version: ${deviceData.browserInfo.version} | language: ${deviceData.browserInfo.language} | cookiesEnabled: ${deviceData.browserInfo.cookiesEnabled} | online: ${navigator.onLine} | userAgent: ${deviceData.browserInfo.userAgent} | doNotTrack: ${navigator.doNotTrack}`;

        // Format system information
        const systemInfoText = `name: ${deviceData.osInfo.name} | version: ${deviceData.osInfo.version}`;

        // Format referrer information
        const referrerText = `redirect: direct | referrer: ${document.referrer || 'direct'}`;

        const visitorFields = {
            visitorId: { 'en-US': visitorId },
            deviceInformation: { 'en-US': deviceInfoText },
            browserInformation: { 'en-US': browserInfoText },
            systemInformation: { 'en-US': systemInfoText },
            displayInformation: { 'en-US': displayInfoText },
            locationInformation: { 'en-US': `timezone: ${Intl.DateTimeFormat().resolvedOptions().timeZone} | url: ${window.location.href}` },
            firstVisit: { 'en-US': localStorage.getItem('first_visit_date') || new Date().toISOString() },
            lastActive: { 'en-US': new Date().toISOString() },
            referrerInformation: { 'en-US': `redirect: direct | referrer: ${document.referrer || 'direct'}` }
        };

        const entry = await environment.createEntry('visitor', {
            fields: visitorFields,
        });
        
        const publishedEntry = await entry.publish();

        if (debug) {
            console.log('Contentful entry created:', publishedEntry);
        }

        return publishedEntry;
    };

  const updateVisitorData = async () => {
    if (!isTrackingEnabled() || updateInProgress.current || !mounted.current) {
        return;
    }

    try {
        updateInProgress.current = true;
        const visitorId = getOrCreateVisitorId();
        const deviceData = getEnhancedDeviceInfo();

        // Check if first visit
        const isFirstVisit = !localStorage.getItem('first_visit_date');
        
        // Store first visit date if not already stored
        if (isFirstVisit) {
            localStorage.setItem('first_visit_date', new Date().toISOString());
            console.log(`First visit detected. Storing first_visit_date and sending data to Contentful for visitor ID: ${visitorId}`);
        } else {
            console.log(`Returning visitor detected. Skipping Contentful save for visitor ID: ${visitorId}`);
        }

        // Send to GA4
        sendGAEvents(visitorId, deviceData);

        // Save to Contentful only for first visit
        if (isFirstVisit) {
            await saveToContentful(visitorId, deviceData);
            console.log('Data successfully sent to Contentful:', visitorId);
        }

        if (debug) {
            console.log('Visitor tracking completed successfully');
        }

    } catch (error) {
        console.error('Error in visitor tracking:', error);

        // Send error to GA4
        if (gaInitialized.current) {
            ReactGA4.event('tracking_error', {
                error_message: error.message,
                error_type: error.name,
                error_location: 'updateVisitorData'
            });
        }
    } finally {
        updateInProgress.current = false;
    }
};
    // Test function to send sample data
    const sendTestEvent = () => {
        if (!gaInitialized.current) {
            initializeGA();
        }

        const testData = {
            visitor_id: 'test-' + new Date().getTime(),
            device_type: 'Test Device',
            device_model: 'Test Model',
            browser_name: 'Test Browser',
            os_name: 'Test OS',
            screen_size: '1920x1080',
            referrer: 'Test Referrer',
            test_event: true
        };

        ReactGA4.event('visitor_data_test', testData);
        console.log('Test event sent to GA4:', testData);
    };

    // Component lifecycle
    useEffect(() => {
        mounted.current = true;
        
        if (isTrackingEnabled()) {
            initializeGA();
            updateVisitorData();
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    // Route change tracking
    useEffect(() => {
        const handleRouteChange = () => {
            if (isTrackingEnabled() && gaInitialized.current) {
                ReactGA4.send({
                    hitType: "pageview",
                    page: window.location.pathname,
                    title: document.title
                });
            }
        };

        window.addEventListener('popstate', handleRouteChange);
        return () => window.removeEventListener('popstate', handleRouteChange);
    }, []);

    // Only render test button in development/debug mode
    if (process.env.NODE_ENV === 'development' || debug) {
        return (
            <div className="fixed bottom-4 right-4 z-50">
                <button
                    onClick={sendTestEvent}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow"
                >
                    Test GA4 Event
                </button>
            </div>
        );
    }

    return null;
};

export default VisitorTracker;